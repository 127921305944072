import { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import Loading from "../components/Loading/Loading";
import { msgCheck } from "../utils/request";
const Home = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [obj, setObj] = useState();

  const submitHandler = async () => {
    const result2 = await msgCheck();

    if (result2 && result2.success && result2.data.check) {
      // console.log("11111111111111111111111111111");
      setObj(result2.data.arrAmount);
      setShow(true);
    }
  };

  useEffect(() => {
    submitHandler();
  }, []);

  return (
    <Box style={{ padding: 20, height: "100%" }}>
      <Box
        sx={{
          bgcolor: "#F3F3F3",
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          height: "100%",
          overflow: "scroll",
          textOverflow: "scroll",
          boxShadow: "rgba(0,0,0,0.35) 0px 5px 15px",
          "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
          },
          transition: "0.3s",
        }}
      >
        {loading && <Loading loading={loading} />}

        <Box
          style={{
            flexDirection: "column",
            display: "flex",

            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Box
            style={{
              // flex: 5,
              width: "100%",
              // maxHeight: "100%",
              flexDirection: "column",
              display: "flex",
              // alignItems: "center",
              // marginTop: "20px",
              // backgroundColor: "red",
            }}
          >
            {show &&
              obj.map((item) => (
                <Typography
                  sx={{
                    direction: "rtl !important",
                    display: "flex",
                    fontSize: {
                      lg: 20,
                      md: 17,
                      sm: 15,
                      xs: 9,
                    },
                    mx: 5,
                    my: 1,
                  }}
                >
                  <h3
                    style={{ color: "#000", fontWeight: "bold", marginTop: -1 }}
                  >
                    *
                  </h3>{" "}
                  {item}
                  <br />
                </Typography>
              ))}

            <Typography
              sx={{
                direction: "rtl !important",
                display: "flex",
                fontSize: {
                  lg: 20,
                  md: 17,
                  sm: 15,
                  xs: 9,
                },
                mx: 5,
                my: 2,
              }}
            >
              <h3 style={{ color: "#000", fontWeight: "bold", marginTop: -1 }}>
                *
              </h3>{" "}
              به هنگام خرید اکانت حتما لینک اکانت خود را از فروشنده دریافت کنید.
              <br />و همیشه با استفاده از آن لینک می توانید از امکانات این سایت
              استفاده کنید.
            </Typography>
            <Typography
              sx={{
                direction: "rtl !important",
                display: "flex",
                fontSize: {
                  lg: 20,
                  md: 17,
                  sm: 15,
                  xs: 9,
                },
                mx: 5,
                my: 2,
              }}
            >
              <h3 style={{ color: "#000", fontWeight: "bold", marginTop: -1 }}>
                *
              </h3>{" "}
              در قسمت info می توانید با استفاده از لینک اکانت خود مقدار حجم
              مصرفی و زمان پایان اکانت را مشاهده کنید.
              <br />
            </Typography>
            <Typography
              sx={{
                direction: "rtl !important",
                display: "flex",
                fontSize: {
                  lg: 20,
                  md: 17,
                  sm: 15,
                  xs: 9,
                },
                mx: 5,
                my: 2,
              }}
            >
              <h3 style={{ color: "#000", fontWeight: "bold", marginTop: -1 }}>
                *
              </h3>{" "}
              در قسمت change با استفاده از لینک اکانت خود می توانید در صورت
              نارضایتی از اکانت خود، تکنولوژی آن را تغییر داده
              <br />
            </Typography>
            <Typography
              sx={{
                direction: "rtl !important",
                display: "flex",
                fontSize: {
                  lg: 20,
                  md: 17,
                  sm: 15,
                  xs: 9,
                },
                mx: 5,
                my: 2,
              }}
            >
              <h3 style={{ color: "#000", fontWeight: "bold", marginTop: -1 }}>
                *
              </h3>{" "}
              بعد از اولین باری که یک اکانت رو در قسمت change تعویض کردید اگر
              بار دیگر انجام دهید اکانت به حالت اولیه باز می گردد پس در این صورت
              بیش از ۲ بار استفاده برای یک اکانت بی فایده خواهد بود و باعث
              افزایش سرعت نمی شود. <br />
            </Typography>
            <Typography
              sx={{
                direction: "rtl !important",
                display: "flex",
                fontSize: {
                  lg: 20,
                  md: 17,
                  sm: 15,
                  xs: 9,
                },
                mx: 5,
                my: 2,
              }}
            >
              <h3 style={{ color: "#000", fontWeight: "bold", marginTop: -1 }}>
                *
              </h3>{" "}
              دقیقا در زمانی که از امکان change استفاده کنید اکانت قبلی دیگر کار
              نخواهد کرد.
              <br />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
