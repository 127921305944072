import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import colors from "../../constans/colors";
import { info } from "../../utils/request";
import momentJ from "jalali-moment";
import moment from "moment";

const InfoTable = ({ trueLoad, falseLoad }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [name, setName] = useState();
  const [dataTwo, setDataTwo] = useState(null);

  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);

  const [obj, setObj] = useState();

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const submitHandler = async () => {
    const check = name.substr(0, 5);

    if (check == "vless" || check == "vmess") {
      await window.localStorage.setItem("url", name);
    }
    setError(false);
    setShow(false);

    trueLoad();
    setObj("");
    const result2 = await info(name);

    // console.log("result2>>>>>>>>>>>>>>>", result2);
    if (result2 && result2.success) {
      setObj(result2.data);
      setShow(true);
      falseLoad();
      setName("");
    }
    if (result2 && !result2.success) {
      setDataTwo(result2.data);

      setError(true);
      falseLoad();
    }
  };

  useEffect(() => {
    const value = window.localStorage.getItem("url");

    if (value) {
      setName(value);
    }
  }, []);

  const style = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: windowSize.width < 700 ? "70%" : "40%",
    // height: "80%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: "15px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
    margin: 1,
    marginTop: windowSize.width < 700 ? "50%" : "15%",

    p: 2.5,
    flexDirection: "column",
    backgroundColor: "#FFF",

    // backgroundColor: "red",
  };

  return (
    <Box
      sx={{
        bgcolor: "#F3F3F3",
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        // height: 0.8,
        height: "100%",
        overflow: "scroll",
        textOverflow: "scroll",
        boxShadow: "rgba(0,0,0,0.35) 0px 5px 15px",
        "&:hover": {
          boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
        },
        transition: "0.3s",
      }}
    >
      <TextField
        // helperText="You can add more than one tag"
        // label={"Name"}
        sx={{
          mx: 5,
          my: 2, // width: "50%",
          // opacity: 0.5,
          borderColor: "red",
          bgcolor: "#FFFF",
          borderRadius: "10px",
        }}
        size="small"
        // {...getFieldProps("tag")}
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      <Button
        // disabled={value == null ? true : false}
        onClick={submitHandler}
        disableElevation
        // disabled={value.id>0 true:false}
        // fullWidth
        size="large"
        type="submit"
        variant="outlined"
        // color="primary"
        sx={{
          //   width: "80%",

          color: colors.blue,
          borderColor: colors.blue,
          "&:hover": {
            backgroundColor: colors.blue,
            color: colors.white,
            borderColor: colors.blue,
          },
          mx: 5,
          my: 2,
        }}
      >
        Info
      </Button>
      {show && (
        <Box
          sx={{
            mt: 5,

            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                Upload
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                {(obj.up / 1000000000).toFixed(2)}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                Download
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                {(obj.down / 1000000000).toFixed(2)}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                Total
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                {(obj.total / 1000000000).toFixed(2)}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color:
                    moment(obj.expiryTime).diff(moment(), "second") <= 0
                      ? "red"
                      : "black",
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                Expire Time
              </Typography>
              <Typography
                sx={{
                  color:
                    moment(obj.expiryTime).diff(moment(), "second") <= 0
                      ? "red"
                      : "black",
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                {momentJ(obj.expiryTime).format("jYYYY/jM/jD")}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                Active
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    lg: 20,
                    md: 15,
                    sm: 11,
                    xs: 9,
                  },
                }}
              >
                {Number(obj.total) <= Number(obj.up) + Number(obj.down) ||
                moment(obj.expiryTime).diff(moment(), "second") <= 0
                  ? "Inactive"
                  : "Active"}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      {error && (
        <Box
          sx={{
            mt: 5,

            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  lg: 20,
                  md: 15,
                  sm: 11,
                  xs: 9,
                },
                color: "red",
              }}
            >
              {dataTwo}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InfoTable;
