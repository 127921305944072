import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import { checkMarjo, marjo } from "../../utils/request";
import colors from "../../constans/colors";
import { chnageAccountFour } from "../../utils/request";
import CloseIcon from "@mui/icons-material/Close";

import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ChangeTable3 = ({ trueLoad, falseLoad }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [name, setName] = useState();
  const [dataTwo, setDataTwo] = useState(null);

  const [checkOne, setCheckOne] = useState(false);
  const [checkTwo, setCheckTwo] = useState(false);

  const [error, setError] = useState(false);
  const [qr, setQr] = useState(false);

  const [remark, setRemark] = useState("");
  const [remarkWifi, setRemarkWifi] = useState("");
  const [proWifi, setProWifi] = useState("");
  const [pro, setPro] = useState("");
  const [copied, setCopied] = useState(false);
  const [copiedWifi, setCopiedWifi] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [click, setClick] = useState(false);
  const [once, setOnce] = useState(null);

  const [type, setType] = useState();
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const submitHandler = async () => {
    const check = name.substr(0, 5);

    if (check == "vless" || check == "vmess") {
      await window.localStorage.setItem("url", name);
    }

    setError(false);

    trueLoad();
    setQr(false);
    setPro("");
    setRemark("");
    setProWifi("");
    setRemarkWifi("");
    const result2 = await chnageAccountFour(name);
    if (result2 && result2.success) {
      if (result2.data.type == 0) {
        setMsg("اکانت از حالت هنگام خرید به حالت جدید تغییر یافت");
        setType(0);
        setPro(result2.data.url);
        setRemark(result2.data.remark);
      }
      if (result2.data.type == 1) {
        setMsg("اکانت از حالت تغییر یافته به حالت اولیه هنگام خرید بازگشت");
        setType(1);
        setProWifi(result2.data.urlW);
        setRemarkWifi(result2.data.remarkWifi);
        setPro(result2.data.url);
        setRemark(result2.data.remark);
      }

      if (result2.data.type == 3) {
        setMsg("اکانت از حالت هنگام خرید به حالت جدید تغییر یافت");
        setType(0);
        setPro(result2.data.url);
        setRemark(result2.data.remark);
      }
      setQr(true);
      setCheckTwo(true);
      setDataTwo("success");

      falseLoad();
      setName("");
    }
    if (result2 && !result2.success) {
      setDataTwo(result2.data);

      setError(true);
      falseLoad();
    }
  };

  useEffect(() => {
    const value = window.localStorage.getItem("url");

    if (value) {
      setName(value);
    }
  }, []);

  const style = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: windowSize.width < 700 ? "70%" : "40%",
    // height: "80%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: "15px",
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
    margin: 1,
    marginTop: windowSize.width < 700 ? "50%" : "15%",

    p: 2.5,
    flexDirection: "column",
    backgroundColor: "#FFF",

    // backgroundColor: "red",
  };

  const unsetFlag = () => {
    setClick(false);
  };

  const handleCloseImg = () => setOpenImg(false);

  return (
    <Box
      sx={{
        bgcolor: "#F3F3F3",
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        // height: 0.8,
        height: "100%",
        overflow: "scroll",
        textOverflow: "scroll",
        boxShadow: "rgba(0,0,0,0.35) 0px 5px 15px",
        "&:hover": {
          boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
        },
        transition: "0.3s",
      }}
    >
      <TextField
        // helperText="You can add more than one tag"
        // label={"Name"}
        sx={{
          mx: 5,
          my: 2, // width: "50%",
          // opacity: 0.5,
          borderColor: "red",
          bgcolor: "#FFFF",
          borderRadius: "10px",
        }}
        size="small"
        // {...getFieldProps("tag")}
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      {click && (
        <Modal
          onClose={unsetFlag}
          open={openImg}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            width: "100%",
            height: "40%",
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Box
              onClick={unsetFlag}
              sx={{
                zIndex: 9999999999,
                justifyContent: "flex-end",
                alignItems: "flex-end",
                display: "flex",
                width: "100%",
                mt: "4%",
              }}
            >
              <IconButton onClick={handleCloseImg}>
                <CloseIcon
                  sx={{
                    pr: "20px",

                    color: "#FFF",
                  }}
                />
              </IconButton>
            </Box>
            <Box sx={style}>
              <QRCode size={250} style={{}} value={once == 1 ? pro : proWifi} />
              <Typography
                sx={{
                  mt: 1,
                  p: 1,
                  borderRadius: 1,
                  backgroundColor: "gray",
                  fontSize: {
                    lg: 25,
                    md: 17,
                    sm: 14,
                    xs: 12,
                  },
                }}
              >
                {once == 1 ? remark : remarkWifi}
              </Typography>
            </Box>
          </Box>
        </Modal>
      )}

      <Button
        // disabled={value == null ? true : false}
        onClick={submitHandler}
        disableElevation
        // disabled={value.id>0 true:false}
        // fullWidth
        size="large"
        type="submit"
        variant="outlined"
        // color="primary"
        sx={{
          //   width: "80%",

          color: colors.blue,
          borderColor: colors.blue,
          "&:hover": {
            backgroundColor: colors.blue,
            color: colors.white,
            borderColor: colors.blue,
          },
          mx: 5,
          my: 2,
        }}
      >
        change
      </Button>
      {qr && (
        <Box
          sx={{
            mt: 3,

            width: "100%",
          }}
        >
          <Box
            sx={{
              // width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              // backgroundColor: "red",
              mb: 0,
            }}
          >
            <Typography
              onClick={() => {
                setOnce(1);
                setClick(true);
                setOpenImg(true);
              }}
              sx={{
                cursor: "pointer",
                color: "red",
                fontSize: {
                  lg: 25,
                  md: 18,
                  sm: 14,
                  xs: 11,
                },
              }}
            >
              {msg}
            </Typography>
          </Box>

          <Box
            sx={{
              // width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              // backgroundColor: "red",
              mb: 3,
            }}
          >
            <Typography
              onClick={() => {
                setOnce(1);
                setClick(true);
                setOpenImg(true);
              }}
              sx={{
                cursor: "pointer",

                fontSize: {
                  lg: 30,
                  md: 20,
                  sm: 15,
                  xs: 15,
                },
              }}
            >
              Qr Code
            </Typography>
            {type == 1 && (
              <Typography
                onClick={() => {
                  setOnce(2);
                  setClick(true);
                  setOpenImg(true);
                }}
                sx={{
                  cursor: "pointer",

                  fontSize: {
                    lg: 30,
                    md: 20,
                    sm: 15,
                    xs: 15,
                  },
                }}
              >
                Qr Code Wifi
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              // width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              // backgroundColor: "red",
            }}
          >
            <CopyToClipboard
              text={pro}
              onCopy={() => {
                setCopiedWifi(false);
                setCopied(true);
              }}
            >
              <Typography
                sx={{
                  cursor: "pointer",
                  color: copied ? "red" : "black",
                  fontSize: {
                    lg: 30,
                    md: 20,
                    sm: 15,
                    xs: 15,
                  },
                }}
              >
                Copy Link
              </Typography>
            </CopyToClipboard>
            {type == 1 && (
              <>
                <CopyToClipboard
                  text={proWifi}
                  onCopy={() => {
                    setCopied(false);

                    setCopiedWifi(true);
                  }}
                >
                  <Typography
                    sx={{
                      cursor: "pointer",
                      color: copiedWifi ? "red" : "black",
                      fontSize: {
                        lg: 30,
                        md: 20,
                        sm: 15,
                        xs: 15,
                      },
                    }}
                  >
                    Copy Link Wifi
                  </Typography>
                </CopyToClipboard>
              </>
            )}
          </Box>
        </Box>
      )}

      {error && (
        <Box
          sx={{
            mt: 5,

            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  lg: 20,
                  md: 15,
                  sm: 11,
                  xs: 9,
                },
                color: "red",
              }}
            >
              {dataTwo}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChangeTable3;
