const colors = {
  gray: "#6D6E71",
  yellow: "#FFC20E",
  yellowHover: "#FFC400",
  blue: "#604FA1",
  white: "#F1F1F1",
  primary: "#99B808",
  grayBack: "#242224",
  backGround: "#1B191B",
  drawer: "#242224",

  black: "black",
  secondary: "#B4B484",
  textPrimary: "#OF0A39",
  textTertiary: "#787B7B",
  textGrey: "#B4B4B4",
  borderColor: "#D4D4D4",
  backgroundColor: "#E4E4E4",
  ratingColor: "#ebc307",
  progressGrey: "#CICIC1",
};

export default colors;
