import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DashboardIcon from "@mui/icons-material/Dashboard";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import GavelIcon from "@mui/icons-material/Gavel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import HomeIcon from "@mui/icons-material/Home";

import TimelineIcon from "@mui/icons-material/Timeline";
export const mainNavbarItems = [
  {
    id: 0,
    icon: <HomeIcon />,
    label: "Home",
    route: "",
  },
  {
    id: 3,
    icon: <InfoIcon />,
    label: "Info",
    route: "info",
  },

  {
    id: 6,
    icon: <ChangeCircleIcon />,
    label: "Change",
    route: "change3",
  },
];
