import * as React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { Button, Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import colors from "../../constans/colors";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,

  // borderRadius: "15px",
  // boxShadow: "0px 1px 40px 0px rgba(0,0,0,0.2)",
  margin: 1,
  p: 4,
  zIndex: 100000,
  backgroundColor: "none",
};
export default function Loading({ loading }) {
  // const [loading, setLoading] = React.useState(false);
  const timerRef = React.useRef();

  return (
    <>
      {loading && (
        <div>
          <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CircularProgress style={{ color: colors.yellowHover }} />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
}

// const [loading, setLoading] = React.useState(false);
// <Loading loading={loading} />
