const ser = "https://info.ponsadose.tk/api/sher/api/v1/vpn";
const local = "http://localhost:6090/api/sher/api/v1/vpn";

export const info = async (url) => {
  if (url) {
    try {
      const rawResponse = await fetch(`${ser}/publicfunc/info`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          store: url,
        }),
      });

      const response = await rawResponse.json();

      if (response.success) {
        return response;
      }
      if (!response.success) {
        return response;
      }
    } catch (err) {
      return false;
    }
  }
};

// createTest server
export const chnageAccountFour = async (url) => {
  if (url) {
    try {
      const rawResponse = await fetch(`${ser}/publicfunc/change`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          store: url,
        }),
      });

      const response = await rawResponse.json();
      if (response.success) {
        return response;
      }
      if (!response.success) {
        return response;
      }
    } catch (err) {
      return false;
    }
  }
};

export const msgCheck = async () => {
  try {
    const rawResponse = await fetch(`${ser}/variablenew/msg`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const response = await rawResponse.json();

    if (response.success) {
      return response;
    }
    if (!response.success) {
      return response;
    }
  } catch (err) {
    return false;
  }
};
