import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import SlideBar from "./SlideBar";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();

  return (
    <>
      {/* <Loading loading={loading} /> */}
      <SlideBar />
    </>
  );
}
