import React, { useState, useEffect } from "react";
import { info } from "../utils/request";
import Loading from "../components/Loading/Loading";
import { decryptData } from "../utils/decryptedData";
import { Box } from "@mui/material";
import InfoTable from "../components/tables/InfoTable";
const Info = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [store, setStore] = useState(null);

  const loadTrue = async () => {
    await setLoading(true);
  };
  const loadFalse = async () => {
    await setLoading(false);
  };

  return (
    <Box
      style={{
        height: "100%",
      }}
    >
      <Loading loading={loading} />
      <InfoTable trueLoad={loadTrue} falseLoad={loadFalse} />
    </Box>
  );
};
export default React.memo(Info);
