import { useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import Home from "./Page/Home";
import Change3 from "./Page/Change3";
import Info from "./Page/Info";
export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "change3",
          element: <Change3 />,
        },
        {
          path: "info",
          element: <Info />,
        },
      ],
    },
  ]);
}
