import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { mainNavbarItems } from "../consts/navbarItems";
import colors from "../../constans/colors";
import { useAuth } from "../../hooks/useAuth";
const drawerWidth = 20;

const openedMixin = (theme) => ({
  borderWidth: 0,
  borderColor: colors.drawer,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  // overflowY: "hidden",
});

const closedMixin = (theme) => ({
  // width: "30px",
  backgroundColor: colors.white,

  borderWidth: 2,
  borderColor: colors.white,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // overflowX: "hidden",
  // overflowY: "scroll",
  width: "70px",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // marginb: 10,
  justifyContent: "flex-end",
  // paddingTop: "100px",
  // padding: theme.spacing(1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  // transition: theme.transitions.create(["width", "margin"], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  ...(open && {
    borderWidth: 0,
    borderColor: colors.drawer,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // width: drawerWidth,
  whiteSpace: "nowrap",

  // backgroundColor: colors.white,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const GuestFooter = () => {
  return (
    <Paper
      sx={{
        zIndex: 1311,
        width: "100%",
        position: "fixed",
        bottom: 0,
        right: 0,
        backgroundColor: colors.white,
        height: "11%",
        boxShadow: "2px",
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Box
        sx={{
          justifyContent: "center",
          pt: 1,
          mb: 1,
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            flexDirection: "column",
            marginRight: 2,
            marginLeft: 2,
            display: "flex",
          }}
        >
          <Typography
            variant="caption"
            color="initial"
            sx={{
              direction: "rtl !important",

              fontSize: {
                lg: 20,
                md: 17,
                sm: 13,
                xs: 10,
              },
            }}
          >
            تایم پاسخگویی پشتیبانی: روزها از ساعت ۱۰-۱۲ بعد از ظهرها ۱۵-۱۸ و شب
            ها ساعت ۲۲:۳۰ مجدد چک می شود و پیام های تا آن لحظه جواب داده می شود.
          </Typography>
          <Typography
            variant="caption"
            // color="initial"
            sx={{
              fontSize: {
                lg: 20,
                md: 17,
                sm: 13,
                xs: 10,
              },
              mt: 0,
              color: "#0088cc",
            }}
          >
            <a
              href="https://t.me/V403403V"
              style={{
                color: "#0088cc",

                // color: "black",
                // fontWeight: "bold",
                // fontSize: "20px",
              }}
            >
              Telegram
            </a>
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

const MainStyle = styled("div")(({ theme }) => ({
  // overflow: "scroll",
  height: "60vh",
  left: 0,
  right: 0,
  marginTop: 110,
  width: "80%",
  overflowY: "scroll",
  // backgroundColor: "red",
  // boxShadow: "rgba(0,0,0,0.35) 0px 5px 15px",
  // "&:hover": {
  // boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
  // },

  [theme.breakpoints.down("sm")]: {
    width: "65%",
  },
}));

export default function SlideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    const name =
      location.pathname == "/dashboard"
        ? "Dashboard"
        : location.pathname == "/allqr"
        ? "All Qr Code"
        : location.pathname == "/buy"
        ? "Buy"
        : "";
    setNameRoute(name);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [nameRoute, setNameRoute] = React.useState("Dashboard");

  const clickLogOut = async () => {};

  const clickHandler = (type, route) => {
    setNameRoute(type);
    navigate(`/${route}`);
  };

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "scroll",
      }}
    >
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: colors.white,
          boxShadow: "2px",
        }}
      >
        <Toolbar style={{ flex: 1 }}>
          <Box
            style={{
              flex: 1,
            }}
          ></Box>

          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              color: colors.gray,
            }}
          >
            <Typography variant="h6" noWrap component="div">
              {nameRoute}
            </Typography>
          </Box>

          <Box
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          ></Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        open={open}
        sx={{
          borderWidth: 0,
          // backgroundColor: "red",

          // overflow: "scroll",
        }}
      >
        {/* ON dokmehe ke mibande */}
        <DrawerHeader
          sx={{
            backgroundColor: colors.white,
            boxShadow: "200px",
          }}
        >
          {/*  */}
        </DrawerHeader>
        <Divider />

        {/* kole icon haye baghale */}
        <List
          sx={{
            backgroundColor: colors.white,
            boxShadow: "2px",
            height: "70%",
            width: "70px",
            overflow: "scroll",
          }}
        >
          {mainNavbarItems.map((text, index) => (
            <Box
              borderRight={1}
              borderColor={
                text.label === nameRoute ? colors.yellow : colors.white
              }
              sx={{}}
            >
              <ListItem
                key={text}
                disablePadding
                sx={{
                  display: "block",
                }}
              >
                <ListItemButton
                  sx={{
                    flexDirection: !open ? "column" : null,
                    justifyContent: open ? "initial" : "center",
                    paddingRight: 1,
                    paddingLeft: 1,
                    marginY: "5px",
                  }}
                  onClick={() => clickHandler(text.label, text.route)}
                >
                  <ListItemIcon
                    sx={{
                      // minWidth: open ? 0 : 1,
                      mr: open ? 3 : "auto",
                      width: !open && "10%",
                      justifyContent: "center",
                      // alignItems: !open && "center",
                      // display: !open && "flex",
                      color: colors.blue,
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <Typography
                    sx={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      // backgroundColor: "red",
                      opacity: open ? 1 : 1,
                      borderWidth: 0,
                      color: colors.gray,

                      borderColor: colors.white,
                      fontSize: {
                        lg: 12,
                        md: 11,
                        sm: 10,
                        xs: 9,
                      },
                    }}
                  >
                    {text.label}
                  </Typography>
                </ListItemButton>
              </ListItem>
            </Box>
          ))}
        </List>
      </Drawer>
      {/* <DrawerHeader /> */}
      <MainStyle>
        <Outlet />
      </MainStyle>
      {/* </Grid> */}
    </Box>
  );
}
